.popup-box{
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
}

.box{
    position: relative;
    width: 70%;
    min-height: 300px;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 4px;
    margin: 20px auto;
    padding: 20px;
    overflow: auto;
}

.btn-close{
    cursor: pointer;
    border: 1px solid #999;
    background-color: #fff;
    border-radius: 50%;
    position: fixed;
    right: calc(16% - 2%);
    top: 15px;
    width: 3%;
    height: 4vh;
}